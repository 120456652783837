






















































































import { Component, Vue } from "vue-property-decorator";
import dayjs from "dayjs";
import * as echarts from "echarts";
import vueSeamless from "vue-seamless-scroll";
import countTo from "vue-count-to";
import axios from "axios";

@Component({
  components: {
    vueSeamless,
    countTo,
  },
})
export default class Home extends Vue {
  time = "-";

  // 订单滚动
  orderNum = ["0", "0", "0", "0", "0", "0"];
  OrderNumber = 823123;
  setNumberTransform() {
    let numberItems = this.$refs.numberItem; // 拿到数字的ref，计算元素数量
    let numberArr: any = this.orderNum.filter((item: any) => !isNaN(item));
    // 结合CSS 对数字字符进行滚动,显示订单数量
    for (let index = 0; index < (numberItems as any).length; index++) {
      const elem = numberItems[index];
      elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`;
    }
  }

  // 处理总订单数字
  toOrderNum(num) {
    num = num.toString();
    // 把订单数变成字符串
    if (num.length < 6) {
      num = "0" + num; // 如未满八位数，添加"0"补位
      this.toOrderNum(num); // 递归添加"0"补位
    } else if (num.length === 6) {
      // 订单数中加入逗号
      this.orderNum = num.split(""); // 将其便变成数据，渲染至滚动数组
    }
  }

  // 统计
  count = {
    jinriMoeny: {
      startVal: 85000,
      endVal: 85000,
    },
    jinriOrder: {
      startVal: 10317,
      endVal: 10317,
    },
    allMoeny: {
      startVal: 8199,
      endVal: 8199,
    },
    allDBMoeny: {
      startVal: 4000245,
      endVal: 4000245,
    },
    allComony: {
      startVal: 50245,
      endVal: 50245,
    },
  };

  // 统计更新
  updateCount() {
    setInterval(() => {
      this.count.jinriMoeny.startVal = this.count.jinriMoeny.endVal;
      this.count.jinriMoeny.endVal += 100;

      this.count.jinriOrder.startVal = this.count.jinriOrder.endVal;
      this.count.jinriOrder.endVal += 30;

      this.count.allMoeny.startVal = this.count.allMoeny.endVal;
      this.count.allMoeny.endVal += 1;

      this.count.allDBMoeny.startVal = this.count.allDBMoeny.endVal;
      this.count.allDBMoeny.endVal += 80;

      this.count.allComony.startVal = this.count.allComony.endVal;
      this.count.allComony.endVal += 3;

      // 订单总数
      this.OrderNumber = this.OrderNumber + 12;
      this.toOrderNum(this.OrderNumber);
      this.setNumberTransform();
    }, 5000);
  }

  created() {
    this.getTime();
  }

  mounted() {
    // 实时统计
    this.updateCount();

    // 保费收入占比
    this.drawLine_pie(this.$refs.chart1, [
      { label: "5000以上", count: 100, color: "#ff0000" },
      { label: "2000-5000", count: 120, color: "#ff9c00" },
      { label: "1000-2000", count: 230, color: "#ffd200" },
      { label: "500-1000", count: 333, color: "#00b4ff" },
      { label: "0-500", count: 503, color: "#0066ff" },
    ]);

    // 交易中心订单排行
    this.drawLine_bar(this.$refs.chart2, [
      { label: "深圳", count: 20, color: "#ff0000" },
      { label: "上海", count: 15, color: "#ff9c00" },
      { label: "成都", count: 13, color: "#ffd200" },
      { label: "广州", count: 10, color: "#afff48" },
      { label: "亳州", count: 10, color: "#00b4ff" },
      { label: "其他", count: 10, color: "#0066ff" },
    ]);

    // 地图
    this.drawLine_map(
      this.$refs.chart3,
      [
        { name: "南昌", value: [115.89, 28.68] },
        { name: "厦门", value: [118.1, 24.46] },
        { name: "上海", value: [121.48, 31.22] },
        { name: "南宁", value: [108.33, 22.84] },
        { name: "温州", value: [120.65, 28.01] },
        { name: "临安", value: [119.72, 30.23] },
        { name: "兰州", value: [103.73, 36.03] },
        { name: "沧州", value: [116.83, 38.33] },
        { name: "南充", value: [106.110698, 30.837793] },
        { name: "泰安", value: [117.13, 36.18] },
      ],
      [
        { name: "南昌", value: [115.89, 28.68] },
        { name: "厦门", value: [118.1, 24.46] },
        { name: "上海", value: [121.48, 31.22] },
        { name: "南宁", value: [108.33, 22.84] },
        { name: "温州", value: [120.65, 28.01] },
        { name: "临安", value: [119.72, 30.23] },
        { name: "兰州", value: [103.73, 36.03] },
        { name: "沧州", value: [116.83, 38.33] },
        { name: "南充", value: [106.110698, 30.837793] },
        { name: "泰安", value: [117.13, 36.18] },
      ]
    );

    // 折线图
    this.drawLine_line(this.$refs.chart4, [
      {
        name: "2022.01",
        value: 220,
      },
      {
        name: "2022.02",
        value: 120,
      },
      {
        name: "2022.03",
        value: 260,
      },
      {
        name: "2022.04",
        value: 420,
      },
      {
        name: "2022.05",
        value: 520,
      },
      {
        name: "2022.06",
        value: 220,
      },
      {
        name: "2022.07",
        value: 20,
      },
      {
        name: "2022.08",
        value: 120,
      },
      {
        name: "2022.09",
        value: 100,
      },
      {
        name: "2022.10",
        value: 320,
      },
      {
        name: "2022.11",
        value: 620,
      },
      {
        name: "2022.12",
        value: 820,
      },
    ]);

    // 折线柱状
    this.drawLine_lineBar(this.$refs.chart5, [
      {
        name: "3.1-3.7",
        firstValue: 22,
        twoValue: 18,
        rate: 0.82,
      },
      {
        name: "3.8-3.15",
        firstValue: 22,
        twoValue: 14,
        rate: 0.64,
      },
      {
        name: "3.16-3.23",
        firstValue: 25,
        twoValue: 10,
        rate: 0.4,
      },
      {
        name: "3.24-3.31",
        firstValue: 20,
        twoValue: 5,
        rate: 0.25,
      },
    ]);

    // 折线图 活跃客户数量
    this.drawLine_line2(this.$refs.chart6, [
      {
        name: "2022.01",
        value: 220,
      },
      {
        name: "2022.02",
        value: 120,
      },
      {
        name: "2022.03",
        value: 260,
      },
      {
        name: "2022.04",
        value: 420,
      },
      {
        name: "2022.05",
        value: 520,
      },
      {
        name: "2022.06",
        value: 220,
      },
    ]);
  }

  // 柱状折线
  myChartLineBar: any;
  async drawLine_lineBar(item: any, list: any) {
    let x_name = [];
    let s_data_first = [];
    let s_data_two = [];
    let s_data_rate = [];
    list.forEach((item: any) => {
      x_name.push(item.name);
      s_data_first.push(item.firstValue);
      s_data_two.push(item.twoValue);
      s_data_rate.push(item.rate);
    });
    this.myChartLineBar = echarts.init(item as HTMLDivElement);
    let _myChart = this.myChartLineBar;
    let _option = {
      xAxis: {
        type: "category",
        data: x_name,
        splitLine: {
          show: false,
          lineStyle: {
            color: "#214890",
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#214890",
          },
        },
        axisLabel: {
          color: "rgba(170, 221, 255, 0.8)",
          interval: 0,
        },
        silent: true,
      },
      yAxis: [
        {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#2c3977",
            },
          },
          axisLabel: {
            color: "rgba(170, 221, 255, 0.8)",
          },
          axisLine: {
            lineStyle: {
              color: "#214890",
            },
            show: true,
          },
          silent: true,
        },
        {
          type: "value",
          show: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#2c3977",
            },
          },
          axisLabel: {
            color: "rgba(170, 221, 255, 0.8)",
          },
          silent: true,
        },
      ],
      series: [
        {
          type: "bar",
          name: "data_first",
          data: s_data_first,
          itemStyle: {
            color: "#00b4ff",
          },
        },
        {
          type: "bar",
          name: "data_two",
          data: s_data_two,
          itemStyle: {
            color: "#ff9c00",
          },
        },
        {
          type: "line",
          name: "data_line",
          yAxisIndex: 1,
          lineStyle: {
            color: "#afff48",
          },
          itemStyle: {
            color: "#afff48",
          },
          data: s_data_rate,
        },
      ],
    };
    _myChart.setOption(_option);
  }

  // 折线图 活跃客户数量
  myChartLine2: any;
  async drawLine_line2(item: any, list: any) {
    let x_name = [];
    let s_data = [];
    list.forEach((item: any) => {
      x_name.push(item.name);
      s_data.push(item.value);
    });
    this.myChartLine2 = echarts.init(item as HTMLDivElement);
    let _myChart = this.myChartLine2;
    let _option = {
      xAxis: {
        type: "category",
        data: x_name,
        //设置网格线颜色
        splitLine: {
          show: false,
          lineStyle: {
            color: "#214890",
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#214890",
          },
        },
        axisLabel: {
          color: "rgba(170, 221, 255, 0.8)",
          interval: 0,
        },
        silent: true,
        boundaryGap: false,
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2c3977",
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#214890",
          },
        },
        axisLabel: {
          color: "rgba(170, 221, 255, 0.8)",
        },
        silent: true,
      },
      series: [
        {
          data: s_data,
          type: "line",
          silent: true,
          symbol: "none",
          lineStyle: {
            color: "#ff9c00",
          },
          itemStyle: {
            color: "#ff9c00",
          },
          areaStyle: {
            color: "#2c2a43",
          },
        },
      ],
    };
    _myChart.setOption(_option);

    // 动态更新
    setInterval(() => {
      s_data = [];
      list.forEach((item: any) => {
        item.value += Math.round(Math.random() * 100);
        s_data.push(item.value);
      });
      _myChart.setOption({
        series: [
          {
            data: s_data,
          },
        ],
      });
    }, 5000);
  }

  // 折线图
  myChartLine: any;
  async drawLine_line(item: any, list: any) {
    let x_name = [];
    let s_data = [];
    list.forEach((item: any) => {
      x_name.push(item.name);
      s_data.push(item.value);
    });
    this.myChartLine = echarts.init(item as HTMLDivElement);
    let _myChart = this.myChartLine;
    let _option = {
      xAxis: {
        type: "category",
        data: x_name,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#214890",
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#214890",
          },
        },
        axisLabel: {
          color: "rgba(170, 221, 255, 0.8)",
          interval: 0,
        },
        silent: true,
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#214890",
          },
        },
        axisLabel: {
          color: "rgba(170, 221, 255, 0.8)",
        },
        silent: true,
      },
      series: [
        {
          data: s_data,
          type: "line",
          silent: true,
          smooth: true,
          symbol:
            "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAACCElEQVQ4jY2UTUgUYRjHf85+6GJYRB/oaloIOUrTx6mDJV06RCWd5pDUlregICKQDtYhqE7bIcJLdAiJgaIuBRUVRbjQIXIwJkItRPtgM2upzTXajWfx3d5ZHdY/zMDzfx5+7zzPvO9bFd10kgpqAaLAGPA3qDQsL8f1fKZtmTuBQ8ABYKWWeghcBR45rpfV6jHKAI22Zd4AngG9ZRDRbuAuMGxb5nY9YegQ4B7QoxesbvhD/bo5qnxL0gqkbMvc5WttXucBSwXdR6fp6v5OfP1cMc79NnBTtVw508BstkS9bVvmFmBCDXsH8Fxlj1+conNPZtGhTo1Xc+5IM5mZkLIuAX0KfVC5+xLTgRBRfEOOU8lJ3UrA/873Krdr/49AiFLbtixtW0s/bS3QKaDlspA4sWV5mlpzFUGiRn9dh4BKzRpGYUkQUSjkC8MC+gYU+/mVCfH1U2RJoM8TUT0cVTO6r5ynd1ZUhHx4W8PwUK0K88ALBbqm3FsDq3j3OhYImUmHGThbr1tyZH4WQY7rPQaeqEx/ooXUg7oFkNGRGBeONfHeq9Ht65Tt7BPAEFBXyMPl03EGk2to3jhLtLrAl8kIYyMLvvSw43qv5NCWQI7rvbEtU3b4TaBdvPTHSPEJUI/jeoMq5zuKjuu5wOb53fpyEUAaSEqNDhFVutjkUpNDKUMZD4AD8A9X/Izkt6ogGQAAAABJRU5ErkJggg==",
          symbolSize: 16,
          lineStyle: {
            color: "#afff48",
          },
          itemStyle: {
            color: "#afff48",
          },
        },
      ],
    };
    _myChart.setOption(_option);

    // 动态更新
    setInterval(() => {
      s_data = [];
      list.forEach((item: any) => {
        item.value += Math.round(Math.random() * 100);
        s_data.push(item.value);
      });
      _myChart.setOption({
        series: [
          {
            data: s_data,
          },
        ],
      });
    }, 5000);
  }

  // 地图构造
  myChartMap: any;
  async drawLine_map(item: any, list: any, gold: any) {
    const res: any = await axios.get("/chinaMap.json");
    echarts.registerMap("china", res.data);
    this.myChartMap = echarts.init(item as HTMLDivElement,'none',{renderer:'svg'});
    let _myChart = this.myChartMap;
    const gb =
      "image://"+require("../assets/latlon.png");
    const jb =
      "image://"+require("../assets/jb.gif")
    const jb_dh = "image://"+require("../assets/jb_dh.png");
    let _option = {
      geo: {
        map: "china",
        zoom: 1.68,
        top: "31.5%",

        label: {
          show: true,
          fontSize: "8",
          color: "#28a7e1",
        },
        itemStyle: {
          borderColor: "#1085AC",
          areaColor: "#1a439a",
        },
        show: false,
        silent: true,
      },
      series: [
        {
          type: "effectScatter",
          coordinateSystem: "geo",
          animation: true, //坐标点是否显示动画
          symbol: gb,
          symbolSize: 14,
          data: list.map((item) => {
            return {
              name: item.name,
              value: item.value,
              itemStyle: {
                normal: {
                  color: "#ffff00",
                },
              },
              label: {
                show: true,
                color: "rgb(255, 255, 0)",
                formatter: "{b}",
                offset: [20, 0],
                fontSize: "10",
              },
            };
          }),
        }
      ],
    };
    _myChart.setOption(_option);

    let isOne = true;

    let changeMap = () => {
      let _kz = {};
      if (isOne) {
        isOne = false;
        _kz = {
          series: [
            {
              type: "effectScatter",
              coordinateSystem: "geo",
              animation: true, //坐标点是否显示动画
              symbol: gb,
              symbolSize: 14,
              data: list.map((item) => {
                return {
                  name: item.name,
                  value: item.value,
                  itemStyle: {
                    normal: {
                      color: "#ffff00",
                    },
                  },
                  label: {
                    show: true,
                    color: "rgb(255, 255, 0)",
                    formatter: "{b}",
                    offset: [20, 0],
                    fontSize: "10",
                  },
                };
              }),
            },
            {
              type: "scatter",
              coordinateSystem: "geo",
              symbol: jb,
              symbolSize: 25,
              data: gold.map((item) => {
                return {
                  name: item.name,
                  value: item.value,
                  itemStyle: {
                    normal: {
                      color: "#ffff00",
                      opacity: 1,
                    },
                  },
                };
              }),
            },
          ],
        };
        _myChart.setOption(_kz);
        setTimeout(() => {
          changeMap();
        }, 3000);
      } else {
        isOne = true;
        _kz = {
          series: [
            {
              type: "effectScatter",
              coordinateSystem: "geo",
              animation: true, //坐标点是否显示动画
              symbol: gb,
              symbolSize: 14,
              data: list.map((item) => {
                return {
                  name: item.name,
                  value: item.value,
                  itemStyle: {
                    normal: {
                      color: "#ffff00",
                    },
                  },
                  label: {
                    show: true,
                    color: "rgb(255, 255, 0)",
                    formatter: "{b}",
                    offset: [20, 0],
                    fontSize: "10",
                  },
                };
              }),
            },
            {
              type: "lines",
              symbolSize: 10,
              effect: {
                show: true,
                period: 1,
                trailLength: 0,
                symbol: jb_dh,
                symbolSize: 25,
                loop: false
              },
              lineStyle: {
                color: "#2d9df1", //航线的颜色
                width: 1,
                opacity: 0,
                curveness: 0.2, //  弯曲程度
              },
              data: gold.map((item) => {
                return {
                  fromName: item.name,
                  toName: "金币",
                  coords: [item.value, [87.68, 51.77]],
                  value: [87.68, 51.77],
                };
              }),
            },
          ],
        };
        _myChart.setOption(_kz);
        setTimeout(() => {
          changeMap();
        }, 5000);
      }
    };
    setTimeout(() => {
      changeMap();
    }, 5000);
  }

  // 无缝滚动
  newList = [
    { name: "深圳******公司", trad: "深圳市公共资源交易中心" },
    { name: "深圳******公司", trad: "亳州市公共资源交易中心" },
    { name: "深圳******公司", trad: "西安市公共资源交易中心" },
    { name: "深圳******公司", trad: "成都市公共资源交易中心" },
    { name: "深圳******公司", trad: "北京市公共资源交易中心" },
    { name: "深圳******公司", trad: "上海市公共资源交易中心" },
    { name: "深圳******公司", trad: "广州市公共资源交易中心" },
    { name: "深圳******公司", trad: "东莞市公共资源交易中心" },
    { name: "深圳******公司", trad: "惠州市公共资源交易中心" },
    { name: "深圳******公司", trad: "厦门市公共资源交易中心" },
  ];
  optionSetting = {
    step: 0.25, // 数值越大速度滚动越快
    limitMoveNum: 2, // 开始无缝滚动的数据量
    hoverStop: true, // 是否开启鼠标悬停stop
    direction: 1, // 0向下 1向上 2向左 3向右
    openWatch: true, // 开启数据实时监控刷新dom
    singleHeight: 30, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
    waitTime: 3000, // 单步运动停止的时间(默认值1000ms)
  };

  // 构建柱状
  myChartBar: any;
  drawLine_bar(item: any, list: any) {
    let _y: any = [];
    let _s: any = [];
    list.forEach((item: any) => {
      _y.unshift({
        value: item.label,
        textStyle: {
          color: "#81ACD2",
        },
      });
      _s.unshift({ value: item.count, itemStyle: { color: item.color } });
    });
    this.myChartBar = echarts.init(item as HTMLDivElement);
    let _myChart = this.myChartBar;
    let _option = {
      grid: {
        top: "20px",
        left: "50px",
        right: "50px",
        bottom: "20px",
        containLabel: true,
      },
      xAxis: {
        show: false,
      },
      yAxis: {
        type: "category",
        inverse: true,
        data: _y,
        axisLabel: {
          formatter: function (params: any) {
            if (params.length > 4) {
              return params.substr(0, 4);
            } else {
              return params;
            }
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#1C68DA",
          },
        },
        animationDuration: 300,
        animationDurationUpdate: 300,
      },
      series: [
        {
          realtimeSort: true,
          type: "bar",
          label: {
            show: true,
            position: "right",
            color: "#B9B4B4",
            formatter: "{c}%",
          },
          barWidth: 10,
          data: _s,
        },
      ],
      animationDuration: 0,
      animationDurationUpdate: 2500,
      animationEasing: "linear",
      animationEasingUpdate: "linear",
    };
    _myChart.setOption(_option);

    // 动态更新
    setInterval(() => {
      for (var i = 0; i < list.length; ++i) {
        _s[i].value = Math.round(Math.random() * 200);
      }
      _myChart.setOption(_option);
    }, 5000);
  }

  // 构建饼状
  myChartPie: any;
  drawLine_pie(item: any, list: any) {
    let _data: any = [];
    list.forEach((item: any) => {
      _data.unshift({
        value: item.count,
        name: item.label,
        itemStyle: { color: item.color },
      });
    });
    this.myChartPie = echarts.init(item as HTMLDivElement);
    let _myChart = this.myChartPie;
    let _option = {
      top: {
        bottom: "20px",
        containLabel: true,
      },
      legend: {
        bottom: -5,
        itemWidth: 10,
        itemHeight: 10,
        selectedMode: false,
        textStyle: {
          color: "#6381AE",
          fontSize: "12px",
        },
      },
      series: [
        {
          name: "保费收入占比",
          type: "pie",
          radius: ["45%", "68%"],
          height: "98%",
          labelLine: {
            length2: 5,
          },
          label: {
            color: "auto",
            formatter: "{d}%\n",
          },
          labelLayout: function (params) {
            const isLeft = params.labelRect.x < _myChart.getWidth() / 2;
            const points = params.labelLinePoints;
            // Update the end point.
            points[2][0] = isLeft
              ? params.labelRect.x
              : params.labelRect.x + params.labelRect.width;
            return {
              labelLinePoints: points,
            };
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "13px",
            },
          },
          data: _data,
        },
      ],
    };
    _myChart.setOption(_option);

    // 动态更新
    let currentIndex = -1;
    setInterval(() => {
      var datalen = list.length;
      // 注释的地方 表示是否高亮
      _myChart.dispatchAction({
        type: "downplay",
        seriesIndex: 0,
        dataIndex: currentIndex,
      });
      currentIndex = (currentIndex + 1) % datalen;
      _myChart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: currentIndex,
      });
      _myChart.dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: currentIndex,
      });
      _data[currentIndex].value += 100;
      _myChart.setOption(_option);
    }, 5000);
  }

  // 时间计时
  getTime() {
    this.time = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
    setTimeout(() => {
      this.getTime();
    }, 1000);
  }
}
