import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

export const constantRoutes: RouteConfig[] = [
  {
    path: '/',
    component: () => import('@/views/index.vue')
  }
]


const router = new VueRouter({
  routes: constantRoutes,
});


export default router;
